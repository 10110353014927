

.heading{
    color: #000;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin : 2rem;
    margin-top:1rem;
}

 .head{
    padding: 1.5rem;
   font-family: 'Roboto', sans-serif;

    
 }
   .parent{
   margin-top: 0.75rem;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
  

   }
   .sub-parent{
    width:45%;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom:30px;
    
   }

   .sub-parent-2{
    display: flex;
    align-items: flex-start;
   }
   
   .img1{
    width:95%;
    height: 220px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    object-fit: cover;
    }

    .img2{
      object-fit: cover;
    }

.cnt {
  display:none;
  position: absolute;
  bottom: 0; /* Initial position at the bottom */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  width: 90%;
  text-align: start;
  animation: slideFromBottom 0.5s ease forwards; /* Apply animation */
}



.sub-parent:hover .cnt {
  animation: slideFromBottom 0.5s ease forwards; /* Reversed animation on hover */
}

@keyframes slideFromBottom {
  0% {
    bottom: -100%; /* Start position */
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    bottom: 0; /* End position */
    opacity: 1; /* End with opacity 1 */
  }
}

.labels{
  font-size: 20px;
  font-weight: bold;
 
}
.text{
  margin-top:4px;
  font-size: 15px;
 

}

.sub-parent:hover .cnt {
    display: block;
     
}

.sub-parent:hover .img1{
  filter: brightness(0.6);
  
}
 @media (min-width: 1024px) {
    .heading{
      margin-top: 4rem;
      font-size:60px;
      margin:3rem;
    }
    .img1{
      height: 290px;
      width:400px;
      
    }
    .labels{
      font-size: 30px;
    }
    .text{
      font-size: 20px;
    }
    
   }
   
   @media (min-width: 1193px) {
    .heading{
      margin-top: 4rem;
      
    }
    
    .img1{
      height: 300px;
      width:450px;
      
    }
    .cnt{
      width:70%;
    }
   
    
   }
   @media (min-width: 1500px) {
    .heading{
      margin-top: 4rem;
      
    }
    .img1{
      height: 330px;
      width:560px;
      
    }
    
    .labels{
      font-size: 26px;
    }
    .text{
      font-size: 20px;
    }
    
   }
    
   
   @media (min-width: 1850px) {
    .heading{
      margin-top: 4rem;
    }
    .img1{
      height:450px;
      width:800px;

    }
    .labels{
      font-size:40px;
    }
    .text{
      font-size: 35px;
    }
   }


   @media (max-width: 650px) {
    .sub-parent{
      width:90%;

    }
    .heading{
      font-size:35px;
    }
   
    .img1{
      border-radius: 4px;
      
    }
    .cnt{
      width:90%;
    }
    
    .labels{
      font-size: 17px;
    }
    .text{
      
      font-size:12px;
    }
    
  
   }


   
  